#search-container {
    margin: 20px 0;
}

#search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

#search-results {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
}

#search-results li {
    margin-bottom: 10px;
}

#search-results a {
    font-size: 1.2em;
    text-decoration: none;
    color: #333;
}

#tag-cloud {
    margin-bottom: 2em;
}

#tag-cloud a {
    display: inline-block;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    text-decoration: none;
    color: #333;
}

#tag-list h2 {
    margin-top: 1em;
}