$color-primary: hsl(0, 0%, 10%);
$color-text: hsl(0, 0%, 20%);
$color-subtext: hsl(0, 0%, 30%);
$color-border:hsl(84, 95%, 54%);
$color-box-background: mix($color-primary, white, 15%);
$border-radius: 4px;
$cyan: #60daaa;
$red: #f45e61;
$font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

@mixin breakpoint($point) {
  @if $point ==papa-bear {
    @media (max-width: 1500px) {
      @content;
    }
  }

  @if $point ==mama-bear {
    @media (max-width: 1200px) {
      @content;
    }
  }

  @if $point ==brother-bear {
    @media (max-width: 850px) {
      @content;
    }
  }

  @else if $point ==baby-bear {
    @media (max-width: 450px) {
      @content;
    }
  }
}

body {
  box-sizing: content-box;
  font-family: $font-family;
  margin: 0 auto;
  line-height: 1.7;
  padding: 4vh 6vw;
  overflow-x: hidden;
  color: $color-text;
  font-size: 1rem;
  max-width: 63em;

  @media (min-width: 820px) {
    font-size: 1.2rem;
  }
}

time {
  display: block;
  color: $color-subtext;
  margin: 0.5em 0 1em;
}

// Footer
footer.fixed-bottom {
  background-color: linear-gradient(to right, grey 25%, yellow 25%, yellow 50%, red 50%, red 75%, teal 75%) 5;;
  font-size: 0.75rem;

  .container {
    flex: 1 0 auto;
    color: gray;
    padding-top: 9px;
    padding-bottom: 8px;
  }

  a {
    color: var(--global-footer-link-color);

    &:hover {
      color: red;
      text-decoration: none;
    }
  }
}

footer.sticky-bottom {
  
  border-top: 5px solid;
  border-image: linear-gradient(to right, grey 25%, orange 25%, orange 50%, red 50%, red 75%, teal 75%) 5;
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 0.9rem;
  text-align: center;
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  max-height: 75vh;
  border-radius: $border-radius;
}

blockquote {
  padding: 1.5em;
  margin: 0;
  font-size: 0.88em;
  background: $color-box-background;
  border-radius: $border-radius;

  p {
    margin: 0;
  }
}

hr {
  width: 100%;
  border: 0;
  height: 1px;
  margin: 1.5em 0;
  background: red;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  margin-bottom: 0;
  padding-bottom: 0;
}

a {
  padding: 0 0.1em;
  text-decoration: none;
  border-bottom: 1px solid orange;
  color: $color-primary;
  &:hover {
    color: black !important;
    background: #fffaf1;
  }
  &:after {
    position: relative;
    top: -0.5em;
    font-size: 0.7em;
    color: #aaaaaa;
  }
  &.internal-link:after,
  &.footnote:after,
  &.reversefootnote:after {
    content: "";
  }
}

*:focus {
  background: #ffe8bc !important;
  color: black !important;
}


#notes-entry-container {
  display: grid;
  grid-gap: 2em;
  grid-template-areas:
    "content"
    "side";

  @media (min-width: 700px) {
    grid-template-columns: 3fr 1fr;
    grid-template-areas: "content side";
  }
}

.backlink-box {
  // background: $color-box-background;
  border: rgb(168, 68, 235) .5mm ridge;
  writing-mode: horizontal-tb;
  padding: 1em;
  border-radius: $border-radius;
  margin: 1em;
}

code {
  background: #f5f5f5;
  padding: 0.1em 0.2em;
  border-radius: 4px;
}

.invalid-link {
  color: #444444;
  cursor: help;
  background: #fafafa;
  padding: 0 0.1em;
}

.invalid-link-brackets {
  color: #ccc;
  cursor: help;
}

.draw {
  transition: color 0.25s;

  &::before,
  &::after {
    // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
    border: 2px solid transparent;
    width: 0;
    height: 0;
  }

  // This covers the top & right borders (expands right, then down)
  &::before {
    top: 0;
    left: 0;
  }

  // And this the bottom & left borders (expands left, then up)
  &::after {
    bottom: 0;
    right: 0;
  }

  &:hover {
    color: $cyan;
  }

  // Hover styles
  &:hover::before,
  &:hover::after {
    width: 100%;
    height: 100%;
  }

  &:hover::before {
    border-top-color: $cyan; // Make borders visible
    border-right-color: $cyan;
    transition:
      width 0.25s ease-out, // Width expands first
      height 0.25s ease-out 0.25s; // And then height
  }

  &:hover::after {
    border-bottom-color: $cyan; // Make borders visible
    border-left-color: $cyan;
    transition:
      border-color 0s ease-out 0.5s, // Wait for ::before to finish before showing border
      width 0.25s ease-out 0.5s, // And then exanding width
      height 0.25s ease-out 0.75s; // And finally height
  }
}

// $content-width: 1000px;
// $breakpoint: 799px;
// $nav-height: 70px;
// $nav-background: #fff;
$nav-font-color: #0c0c0c;
// $link-hover-color: #f8b500;

// @import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');


// .navigation {
//   height: $nav-height;
//   background: $nav-background;
// }

// .nav-container {
//   max-width: $content-width;
//   margin: 0 auto;
// }

// .brand {
//   position: absolute;
//   padding-left: 20px;
//   float: left;
//   line-height: $nav-height;
//   text-transform: uppercase;
//   font-size: 1.4em;

//   &:hover {}

//   a,
//   a:visited {
//     color: $nav-font-color;
//     text-decoration: none;
//   }
// }
// .logo {
//   display: block;
//   float: left;
//   font-size: 2em;
//   padding: 10px 20px;
//   text-decoration: none;
// }

// nav {
//   margin: 1em 0 3em;

//   ul {
//     list-style: none;
//     margin: 0;
//     padding: 0;

//     li {
//       float: right;
//       position: relative;

//       a {
//         display: block;
//         padding: 10px 20px 0 20px;
//         line-height: 50px;
//         background: $nav-background;
//         color: $nav-font-color;
//         text-decoration: none;


//         &::before {
//           transition: 300ms;
//           height: 5px;
//           content: "";
//           position: absolute;
//           background-color: $link-hover-color;
//           width: 0%;
//           bottom: 0px;
//         }

//         &:hover::before {
//           width: 70%;
//         }

//         &:not(:only-child):after {
//           padding-left: 4px;
//           content: ' ▾';
//         }
//       }

//       // Dropdown list
//       ul li {
//         min-width: 190px;

//         a {
//           padding: 15px;
//           line-height: 20px;
//         }
//       }
//     }
//   }
// }


// .nav-dropdown {
//   display: none;
//   position: absolute;
//   z-index: 1;
//   box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
// }

// .nav-mobile {
//   display: none;
//   position: absolute;
//   top: 0;
//   right: 0;
//   background: $nav-background;
//   height: $nav-height;
//   width: $nav-height;
// }

// @media only screen and (max-width: $breakpoint) {
//   .nav-mobile {
//     display: block;
//   }

//   nav {
//     width: 100%;
//     padding: $nav-height 0 15px;

//     ul {
//       display: none;

//       li {
//         float: none;

//         a {
//           padding: 15px;
//           line-height: 20px;

//           &:hover::before {
//             width: 20%;
//           }
//         }

//         ul li a {
//           padding-left: 30px;
//         }
//       }
//     }
//   }

//   .nav-dropdown {
//     position: static;
//   }
// }

// #nav-toggle {
//   position: absolute;
//   left: 18px;
//   top: 22px;
//   cursor: pointer;
//   padding: 10px 35px 16px 0px;

//   span,
//   span:before,
//   span:after {
//     cursor: pointer;
//     border-radius: 1px;
//     height: 5px;
//     width: 35px;
//     background: $link-hover-color;
//     position: absolute;
//     display: block;
//     content: '';
//     transition: all 300ms ease-in-out;
//   }

//   span:before {
//     top: -10px;
//   }

//   span:after {
//     bottom: -10px;
//   }

//   &.active span {
//     background-color: transparent;

//     &:before,
//     &:after {
//       top: 0;
//     }

//     &:before {
//       transform: rotate(45deg);
//     }

//     &:after {
//       transform: rotate(-45deg);
//     }
//   }
// }

// @media screen and (min-width: $breakpoint) {
//   .nav-list {
//     display: block !important;
//   }
// }
.navbar {
  font-size: 18px;
  // background-image: linear-gradient(260deg, #2376ae 0%, #c16ecf 100%);
  // border: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}

.main-nav {
  list-style-type: none;
  display: none;
}

.nav-links,
.logo {
  text-decoration:  none;
  color: black;
}

.main-nav li {
  text-align: center;
  margin: 15px auto;
}

.logo {
  display: inline-block;
  font-size: 30px;
  font-family: URW Chancery L, cursive;
  margin-top: 10px;
  margin-left: 20px;
}

.navbar-toggle {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
}

#chkToggle {
  display: none;
}

#chkToggle:checked+ul.main-nav {
  display: block;
}

@media screen and (min-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    height: 70px;
    align-items: center;
  }

  #chkToggle:checked+ul.main-nav {
    display: flex;
  }

  .main-nav {
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
  }

  .main-nav li {
    margin: 0;
  }

  .nav-links {
    margin-left: 40px;
    color:#000000;
  }

  .logo {
    margin-top: 0;
  }

  .navbar-toggle {
    display: none;
  }

  .logo:hover,
  .nav-links:hover {
    color: rgba(255, 255, 255, 1);
  }
}

/* DEMO-SPECIFIC STYLES */
.typewriter h1 {
  color:olivedrab;
  font-family: monospace;
  overflow: hidden;
  
  /* Ensures the content is not revealed until the animation */
  // border-right: .15em solid orange;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em;
  @include breakpoint(baby-bear) {
      font-size: 1em;
      letter-spacing: .05em;
    }
  /* Adjust as needed */
  animation:
    typing 3.5s steps(30, end);
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

